import { mapActions, mapGetters } from 'vuex';

//---helpers
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';

export default {
  name: 'Ingredients',
  mixins: [dynamicHead],
  components: { BreadcrumbsSimple },
  props: {},
  data() {
    return {
      letterArray: [],
      selectedLetter: null,
      ingredientList: [],
      isIngredientListLoading: true
    };
  },
  created() {
    globalLoader(true);
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      metaData: 'meta/metaData'
    }),
    breadcrumbs() {
      return [
        { title: this.$t('catalog'), route: { name: 'products' } },
        { title: this.$t('product'), route: { name: 'products' } }
      ];
    }
  },

  methods: {
    ...mapActions({
      getIngredientsLetters: 'ingredients/GET_INGREDIENTS_LETTERS',
      getIngredientsList: 'ingredients/GET_INGREDIENTS',
      getMetaData: 'meta/GET_META_DATA'
    }),
    async _loadContent() {
      let promiseCollection = [];
      // let { series } = this.$route.params;
      promiseCollection.push(this.getIngredientsLetters().then((result) => this._prepareLetters(result)));
      promiseCollection.push(this._getList(null));
      promiseCollection.push(
        this.getMetaData('ingredienty').then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    },
    _prepareLetters(data) {
      this.letterArray = Object.keys(data).reduce(
        (acc, key) => {
          return data[key] ? [...acc, { id: key, title: data[key] }] : acc;
        },
        [{ id: 'all', title: this.$t('page.ingredients.labelAll') }]
      );
    },
    _getList(letter) {
      this.isIngredientListLoading = true;
      this.getIngredientsList(letter ? { letter } : {})
        .then((result) => {
          this.ingredientList = result.data;
        })
        .finally(() => {
          this.isIngredientListLoading = false;
        });
    },
    onChangeLetter() {
      let letter = null;
      if (this.selectedLetter) {
        let letterItem = this.letterArray.find((item) => item.id === this.selectedLetter);
        letter = letterItem && letterItem.id !== 'all' ? letterItem.title : null;
      }
      this._getList(letter);
    }
  }
};
